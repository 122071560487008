<script setup>
const utilsStore = useUtilsStore();
utilsStore.setGlobalLoader(false);
</script>

<template>
  <ClientOnly>
    <v-card>
      <v-layout class="login-page-background">
        <v-main>
          <slot />
        </v-main>
      </v-layout>
    </v-card>
  </ClientOnly>
</template>
